.close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 70px;
    font-weight: bold;
    cursor: pointer;
    color: #ffffff;
}

.close-btn:hover {
    color: #333;
}

.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
}

.popup-overlay.show {
    opacity: 1;
    visibility: visible;
}

.popup-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 460px;
    transform: translateY(-20px);
    transition: transform 0.5s ease-in-out;
}

.popup-overlay.show .popup-content {
    transform: translateY(0);
}