/* General table styling */
.MuiTableRow-root > * {
  padding: 10px;
}

.MuiTableRow-root.MuiTableRow-head > * {
  font-weight: bold !important;
}

.Table td,
.Table th {
  border: none !important;
}

.Table:first-child {
  border-radius: 0.7rem !important;
}

.status {
  padding: 8px;
  border-radius: 9px;
}

.row-title {
  color: rgb(61, 61, 61);
}
.title {
  color: wheat !important;
  font-size: 15px !important;
}
.content-table {
  font-size: 13px !important;
}
/* Container for scrolling */
.TableContainer {
  max-height: 750px; /* Adjust the height as needed */
  overflow-y: auto; /* Enables vertical scrolling */
  overflow-x: auto; /* Enables horizontal scrolling if needed */
  width: 100%; /* Ensure full width */
  display: block; /* Ensures block layout for overflow */
  border-radius: 0.7rem; /* Optional: add border radius for styling */
}

/* Responsive adjustments */
@media screen and (max-width: 1200px) {
  .TableContainer {
    width: 100%; /* Ensure full width on smaller screens */
    margin-top: 2rem;
  }
}

@media screen and (max-width: 768px) {
  .TableContainer {
    width: 100%; /* Ensure full width on mobile devices */
    overflow-x: auto; /* Enables horizontal scrolling */
  }
}
