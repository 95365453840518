.contact-form{
    max-width: 460px;
}
.contact-form .form-control{
    width: 100%;
    background-color: transparent;
    border: 1px solid var( --dark-color);
    border-radius: 35px;
    min-height: 66px;
    font-size: 22px;
    padding: 0 3rem;
    line-height: 66px;
    margin-bottom: 2.6rem;
    outline: none;
    font-family: inherit;
    font-weight: 200;
}
.contact-form textarea.form-control{
    resize: none;
}
.contact-form .submit-btn{
    width: 100%;
    height: 66px;
    border-radius: 35px;
    border: 2px solid var(--brown-color);
    transition: var(--transition);
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
}

input[type=number] {
  -moz-appearance: textfield;
}
.custom-modal-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  padding: 24px;
  text-align: center;
  outline: none; /* Loại bỏ viền mặc định khi modal được chọn */
}

.custom-modal-box h2 {
  margin-bottom: 16px;
  font-size: 24px;
  color: #333;
}

.custom-modal-box p {
  font-size: 16px;
  color: #555;
  margin-bottom: 24px;
}

.custom-modal-button {
  background-color: rgb(53, 53, 53);;
  color: #fff;
  font-weight: bold;
  padding: 10px 20px;
  border-radius: 4px;
  text-transform: none;
}

.custom-modal-button:hover {
  background-color: rgb(53, 53, 53);;
}
