.services-list{
    row-gap: 2rem;
}
.services-item{
    padding: 2.4rem;
}
.services-item-img{
    margin-bottom: 0.8rem;
}
.services-item-img img{
    width: 80px;
}
.services-item-text .text{
    max-width: 460px;
}
.img {
    display: flex;
    justify-content: center; 
    align-items: center;     
    height: 100%;            
}
.img img {
    max-width: 100%;
    height: auto;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    font-size: 1em;
    font-family: Arial, sans-serif;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

table thead tr {
    background-color:rgb(53, 53, 53);
    color: #ffffff;
    text-align: left;
}

table th, table td {
    padding: 12px 15px;
    border: 1px solid #dddddd;
}

table tbody tr {
    border-bottom: 1px solid #dddddd;
}

table tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
}

table tbody tr:last-of-type {
    border-bottom: 2px solid rgb(53, 53, 53);
}

ul {
    padding-left: 20px;
    margin: 0;
}

table th {
    font-weight: bold;
    text-transform: uppercase;
}

table td {
    vertical-align: top;
}



@media screen and (min-width: 768px){
    .services-list{
        grid-template-columns: repeat(2, 1fr);
        column-gap: 2rem;
    }
}


@media screen and (min-width: 992px){
    .services-list{
        grid-template-columns: repeat(3, 1fr);
    }
}