.Dashboard {
    /* padding: 0.5rem 3.5rem; */
    color: var(--black);
    background: white;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Inter', sans-serif;
  }
  
  .AppGlass {
    display: grid;
    height: 97%;
    width: 97%;
    background: var(--glass);
    border-radius: 2rem;
    gap: 16px;
    grid-template-columns: 11rem auto 20rem;
    overflow: hidden;
  }
.MainDash{
    /* padding-top: 1rem; */
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

@media screen and (max-width: 1200px) {
    .MainDash{
        justify-content:flex-start;
        margin-top: 2rem;
    }
}

@media screen and (max-width: 768px)
{
    .MainDash{
        align-items: center;
    }
}
