.header {
    position: relative;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background: url("../../assets/images/tong_quan_3.png") center/cover no-repeat;

}
.header > .container{
    height: 100%;
    flex: 1;
    width: 50%;
}
.header-title{
    font-size: 28px;
    line-height: 1.4;
    margin-top: 1.8rem;
    max-width: 600px;
}
.header .btn-groups{
    margin-top: 4.8rem;
}
.header .btn-groups .btn-item{
    font-size: 1.6rem;
    padding: 1.4rem 2rem;
}
.header .btn-groups .btn-item:first-child{
    border-color: var(--brown-color);
}
.header .btn-groups .btn-item:last-child{
    border-color: var(--dark-color);
}

.about-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
    padding-top: 2%;
}
.vidPlayBtn {
    position: relative;
    z-index: 2;
    background: rgba(0, 0, 0, 0.5);
    padding: 1rem;
    border-radius: 50%;
    cursor: pointer;
}

.vidPlayBtn:hover {
    background: rgba(0, 0, 0, 0.7);
}

.text-brown {
    color: var(--brown-color);
}

.images-header {
    width: 100%;
    height: 50%;
}
@media screen and (min-width: 768px){
    .header-title{
        font-size: 40px;
    }
    .header .btn-groups .btn-item{
        font-size: 2.2rem;
        padding: 1.4rem 3.4rem;
    }

    
}

@media screen and (min-width: 992px){
    .header-title{
        font-size: 48px;
    }
}

@media screen and (max-width: 430px) {
    .header {
        min-height: 23vh;
    }
}