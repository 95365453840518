.services-list {
    row-gap: 2rem;
}

.services-list-2-re {
    grid-template-columns: 65% 35%;
}

.services-list-3-re {
    grid-template-columns: 100%
}

.about-img-slide {
    width: 100%;
    height: auto;
    width: 400px;
    /* Điều chỉnh kích thước tối đa */
    height: 400px;
    /* Điều chỉnh chiều cao tối đa */
    object-fit: cover;
}

.services-item {
    padding: 2.4rem;
}

.services-item-img {
    margin-bottom: 0.8rem;
}

.services-item-img img {
    width: 80px;
}

.services-item-text .text {
    max-width: 460px;
}

.container-video {
    position: relative;
    width: 100%;
    height: 100%;
}

.about-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.vidPlayBtnn {
    position: absolute;
    bottom: 20px;
    right: 20px;
    z-index: 2;
    background: rgba(0, 0, 0, 0.5);
    padding: 1rem;
    border-radius: 50%;
    cursor: pointer;
}

.vidPlayBtnn:hover {
    background: rgba(0, 0, 0, 0.7);
}

.custom {
    text-align: center;
    margin-bottom: 40px !important;
}

.text-brown {
    color: var(--brown-color);
}

.services-list-2-re {
    grid-template-columns: 65% 35%;
    display: grid;
    align-items: center;
    /* Ensure content is vertically centered */
}

.slide-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.slide-description {
    text-align: center;
    margin-top: 1rem;
    /* Adjust spacing as needed */
}

.about-img-slide {
    width: 400px;
    height: 400px;
    object-fit: cover;
}

.about-img-slide-3 {
    width: 100%;
    height: 500px;
    object-fit: cover;
}

.about-img-slide-1 {
    width: 100%;
    height: 600px;
    object-fit: cover;
}

.about-img-slide-2 {
    width: 100%;
    height: 750px;
    object-fit: cover;
}

.slide-show {
    padding-top: 2%;
}

body.no-scroll {
    overflow: hidden;
}

.fade-enter {
    opacity: 0;
}

.fade-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in-out;
}

.fade-exit {
    opacity: 1;
}

.fade-exit-active {
    opacity: 0;
    transition: opacity 500ms ease-in-out;
}

.tab-buttons {
    display: flex;
    justify-content: space-around;
}

.tab-content {
    margin-top: 20px;
}

/* CSS chung cho tất cả các button */
.custom-button {
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    margin-right: 10px;
    outline: none;
  }
  
  .text-brown-btn {
    background-color: var(--brown-color);
    color: white;
  }
  
  .text-dark-btn {
    background-color: #343a40;
    color: white;
  }

  .custom-button:hover {
    opacity: 0.85;
    transform: scale(1.05);
  }
  
  .custom-button:active {
    transform: scale(0.98);
    background-color: #6b4228;
  }
  

@media screen and (min-width: 768px) {
    .services-list {
        grid-template-columns: repeat(2, 1fr);
        column-gap: 2rem;
    }
}


@media screen and (min-width: 992px) {
    .services-list {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media screen and (max-width: 430px) {
    .about-img-slide-1, 
    .about-img-slide-2{
        width: 100%;
        height: 100%;object-fit: cover;
        display: block;
    }

    .about-img-slide {
        width: 100%;
        height: 280px;
    }

    .about-img-slide-3 {
        width: 100%;
        height: 300px;
    }
}