.workprocess-list{
    row-gap: 3rem;
    padding-top: 4rem;
}
.workprocess-item{
    text-align: left;
    padding: 1rem;
    box-shadow: rgba(149, 157, 165, 0.1) 0px 8px 24px;
    transition: var(--transition);
}

.workprocess-item-img {
    cursor: pointer;
}
.workprocess-item:hover{
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.workprocess-item-title{
    align-items: baseline;
}
.workprocess-item-title span:first-child{
    font-size: 45px;
    margin-right: 1.6rem;
}

.image-box-content .image-box-close-btn{
    position: absolute;
    top: 25px;
    right: 25px;
    color: rgb(53, 53, 53);
}
.image-box-close-btn{
    cursor: pointer;
}
.title_mb {    
    text-align: center;
    font-size: 20px;
    padding-top: 5%;
    font-weight: 700;
}

.img-apartment {
    height: 100%;
}

.img_tq_2 {
    max-height: 730px;
}

@media screen and (min-width: 678px){
    .workprocess-list{
        grid-template-columns: repeat(2, 1fr);
        column-gap: 3rem;
    }
}

@media screen and (min-width: 992px){
    .workprocess-list{
        grid-template-columns: repeat(2, 1fr);
    }
}